<template>
  <div class="container-fluid">
    <div class="row" v-if="user">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title float-left" v-if="user.id"> Foydalanuvchi ma'lumotlarini o'zgartirish</h4>
            <h4 class="card-title float-left" v-else> Foydalanuvchi qo'shish</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="username">Login <span class="red">*</span></label>
                  <input type="text" class="form-control" id="username" v-model="user.username">
                </div>
                <div class="form-group">
                  <label for="firstName">Ismi <span class="red">*</span></label>
                  <input type="text" class="form-control" id="firstName" v-model="user.firstName">
                </div>
                <div class="form-group">
                  <label for="lastName">Sharifi <span class="red">*</span></label>
                  <input type="text" class="form-control" id="lastName" v-model="user.lastName">
                </div>
                <div class="form-group">
                  <label for="lastName">Otasining ismi <span class="red">*</span></label>
                  <input type="text" class="form-control" id="middleName" v-model="user.middleName">
                </div>
                <div class="form-group">
                  <label>Vazifasi <span class="red">*</span></label>
                  <div class="row">
                    <div class="col-md-12">
                      <treeselect v-model="user.roles" placeholder="Танланг..." :options="roleList" :multiple="true" value-format="object" />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <label>Status <span class="red">*</span></label>
                    </div>
                    <div class="col-12">
                      <div class="form-check form-check-radio checkbox-inline pl-0" style="margin-left:-0.8rem">
                        <label class="form-check-label">
                          <input class="form-check-input" type="radio" name="status" value="true" v-model="user.active">
                          <span class="form-check-sign"></span>
                          Aktiv
                        </label>
                      </div>
                      <div class="form-check form-check-radio checkbox-inline">
                        <label class="form-check-label">
                          <input class="form-check-input" type="radio" name="status" value="false" v-model="user.active">
                          <span class="form-check-sign"></span>
                          Aktiv emas
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-12 text-center">
                <button class="btn btn-default" @click="cancel" :disabled="processing">Bekor qilish</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'

export default {
  name: "UserEdit",
  components: {
    Treeselect
  },
  data() {
    return {
      processing: false,
      user: {
        id: null,
        status: 'ACTIVE',
        username: '',
        fullName: '',
        firstName: '',
        lastName: '',
        middleName: '',
        company: null,
        roles: [],
        activated: false
      },
      roleList: [],
      companies: []
    }
  },
  methods: {
    cancel() {
      this.$router.push('/user')
    },
    load() {
      const id = this.$route.params.id
      if (id) {
        this.$http.get('user/' + id)
            .then(res => {
              this.user = res.data.data
              for (let i = 0; i < this.user.roles.length; i++){
                this.user.roles[i].label = this.user.roles[i].name
              }
            }, err => {
              console.log(err)
            });
      }
    }
  },
  created() {
    this.load()
  }
}
</script>

<style scoped>

</style>